// @team Growth Leads

const getApplicationEnvironmentFromHost = () => {
  const { host } = window.location;

  if (host === 'gusto.com') {
    return 'production';
  }
  if (host === 'gusto-staging.com') {
    return 'staging';
  }
  if (host.endsWith('.gusto-preview.com')) {
    return 'preview';
  }
  if (host === 'gusto-dev.com') {
    return 'development';
  }

  return 'development';
};

export default getApplicationEnvironmentFromHost;
