// Decouples reporting errors from the service used, enabling
// us if we want to switch error tracking tools in the future
//
// Copied from ZP.
// https://github.com/Gusto/zenpayroll/blob/development/frontend/javascripts/lib/track_error.ts

import datadogRum from '../entrypoints/datadog_rum';

type Severity = 'info' | 'warning' | 'error';

type TrackableError = {
  custom?: object;
  error: Error;
  fingerprint?: string;
  severity?: Severity;
};

type TrackableCrash = TrackableError & {
  errorPresentationStyle: 'error_page' | 'alert_bar' | 'error_message' | 'widget';
};

type CustomError = {
  custom?: object;
  fingerprint?: string;
  message: string;
  name: string;
  severity?: Severity;
};

// The methods in this file create Datadog RUM events, each method adds specific metadata that allows us to distinguish between the types of events.
// This metadata is interpreted in Datadog triggers and dashboards and helps drive visibility and business accountability.

// These events are counted as #front-end-crashes and are observed by our standardized Datadog monitors.
// The intent of these events is to capture when our product encounters an unexpected error. A user attempts to do something, our system fails, and we don't know why.
// Crashes can be recoverable, either by reloading the page or re-rendering the component.
export const trackCrash = ({
  custom = {},
  error,
  errorPresentationStyle,
  fingerprint = '',
  severity = 'info',
}: TrackableCrash) => {
  datadogRum.addError(error, {
    ...custom,
    browser_reload_required: true,
    error_presentation_style: errorPresentationStyle,
    fingerprint,
    severity,
    track_error: true,
  });
};

export function trackCrashRetry(error: Error | null) {
  datadogRum.addAction('crash-retry', { error });
}

export const trackCustomError = ({
  custom = {},
  fingerprint = '',
  message,
  name,
  severity = 'info',
}: CustomError) => {
  const errorWrapper = new Error(message);
  errorWrapper.name = name;
  trackError({
    custom,
    error: errorWrapper,
    fingerprint,
    severity,
  });
};

// These events will trigger your teams datadog monitors, it will not count as a front-end crash.
export const trackError = ({
  custom = {},
  error,
  fingerprint = '',
  severity = 'info',
}: TrackableError) => {
  datadogRum.addError(error, {
    ...custom,
    fingerprint,
    severity,
    track_error: true,
  });
};

// These events *will not* trigger datadog monitors nor will they count as front-end crashes
export const trackWarning = ({
  custom = {},
  error,
  fingerprint = '',
  severity = 'info',
}: TrackableError) => {
  datadogRum.addError(error, {
    ...custom,
    fingerprint,
    severity,
    track_error: false,
  });
};
