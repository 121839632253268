import PerformanceHelper from '../helpers/performance_helper';
import { trackError } from '../helpers/track_error';

// Report page load performance to Gusto Analytics after 5 seconds.
const performanceHelper = new PerformanceHelper();
performanceHelper.executeFunctionOnLoad(() => {
  const data = performanceHelper.getPageLoadInformation();

  if (data === null) {
    return;
  }

  const pageLoad = {
    eventCategory: 'GustoPerformance',
    eventName: 'PageLoad',
    data: data.pageLoad,
  };

  /**
   * Safety helper to avoid duplication of code
   *
   * @param reportTo          Object to report data to
   * @param payload           The payload to report
   * @param trackingMethod   Whether to use track or push
   */
  const report = (reportTo, payload, trackingMethod) => {
    if (!reportTo || !payload || !payload.data) {
      return;
    }

    try {
      if (trackingMethod) {
        reportTo.track(payload);
      } else {
        reportTo.push(payload);
      }
    } catch (e) {
      trackError({
        error: new Error('This is a handled error. Just FYI.'),
        custom: { exception: e },
      });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  report(window.GustoAnalytics, pageLoad, true);
}, null);
