import authenticityToken from './authenticity_token';

// Wrap fetch(...) with some default options
export default function http(url, opts = {}) {
  const options = {
    credentials: 'same-origin',
    headers: {},
    ...opts,
  };
  options.headers['X-CSRF-Token'] = authenticityToken();

  return fetch(url, options);
}

export function postJson(url, payload) {
  return http(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText, {
      cause: { status: response.status, statusText: response.statusText },
    });
  });
}
