const SERVICE_WORKER_PATH = '/service_worker.js';
export default function getServiceWorkerStatus() {
  if ('serviceWorker' in window.navigator) {
    const scriptURL = window.navigator.serviceWorker.controller?.scriptURL;
    if (!scriptURL) {
      return 'supported';
    }
    const url = new URL(scriptURL);

    return url.pathname === SERVICE_WORKER_PATH ? 'controlled' : 'other';
  }
  return 'unsupported';
}
