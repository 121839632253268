import http from 'requests/http';
import getCookieValue from 'helpers/get_cookie';
import getSearchParams from 'helpers/get_search_parameters';

// ** Notes of how and where to use this **
// - Use this helper functions only from a Javascript file or React component
// - This assumes you have 1 control and 1 or multiple variables named "variation" or "variation_n"

export type EvaluationListener = (experimentId: string, enabled: boolean) => void;

declare global {
  interface Window {
    // I stuck on the problem to maintain a singleton for a browser
    // https://gustohq.slack.com/archives/C742U8E0L/p1709865875761669
    // That is why window global is here.
    gustoFfeEvaluationListeners?: Set<EvaluationListener>;
  }
}

export const addEvaluationListener = (func: EvaluationListener) => {
  window.gustoFfeEvaluationListeners = window.gustoFfeEvaluationListeners ?? new Set();
  window.gustoFfeEvaluationListeners.add(func);
};

const notifyEvaluationListeners = (experimentId: string, enabled: boolean) => {
  if (!experimentId) return;
  window?.gustoFfeEvaluationListeners?.forEach(func => {
    func(experimentId, enabled);
  });
};

/**
 * Enrolls a user into an experiment using GustoFFE SDK
 * @param experimentId - The id of the experiment
 * @param skipExistingUsers - True if you want this experiment to enrol only new users / False to include both new/existing users
 */
export const enrollInExperiment = async (
  experimentId: string,
  skipExistingUsers = true
): Promise<boolean> => {
  // When this 'synthetic_test_skip_experiments' cookie is present we will skip the experiment registration. We will setup the cookie in the synthetic tests configuration as true in order to skip this experiment.
  const skipForSyntheticTest = getCookieValue('synthetic_test_skip_experiments');
  const skipForExistingUsers = skipExistingUsers && getCookieValue('has_signed_in');
  if (skipForSyntheticTest) return false;
  if (skipForExistingUsers) return false;
  const searchParams = getSearchParams();

  // this http request will call the Ruby GustoFFE SDK to register the experiment
  const response = await http(`/gusto_ffe/enroll_experiment/${experimentId}${searchParams}`);
  const data = await response.text();
  return data === 'true';
};

/**
 * Enrolls a user into an experiment and returns the variable using GustoFFE SDK
 * @param experimentId - The id of the experiment
 * @param variable - The name of the variable
 */
export const getFeatureVariable = async (
  experimentId: string,
  variable: string
): Promise<boolean | string | number | object> => {
  const searchParams = getSearchParams();
  const response = await http(
    `/gusto_ffe/feature_variable/${experimentId}/${variable}${searchParams}`
  );
  const data = await response.text();
  notifyEvaluationListeners(experimentId, !!data);
  return data;
};

/**
 * Enrolls a user into an experiment and returns the variable using GustoFFE SDK
 * @param experimentId - The id of the experiment
 * @param variable - The name of the variable
 */
export const getGlobalVariable = async (
  experimentId: string,
  variable: string
): Promise<string> => {
  const searchParams = getSearchParams();
  const response = await http(
    `/gusto_ffe/global_variable/${experimentId}/${variable}${searchParams}`
  );
  const data = await response.text();
  notifyEvaluationListeners(experimentId, !!data);
  return data;
};

/**
 * Returns true if a feature is enabled or false if a feature is disabled GustoFFE SDK
 * Must be used only with features that don't have an experiment configured
 * @param experimentId - The id of the experiment
 */
export const isFeatureEnabled = async (experimentId: string): Promise<boolean> => {
  const searchParams = getSearchParams();
  const response = await http(`/gusto_ffe/enroll_experiment/${experimentId}${searchParams}`);
  const data = await response.text();
  const result = data === 'true';
  notifyEvaluationListeners(experimentId, result);
  return result;
};

/**
 * For Optimizely FX - Returns true if a feature is enabled or false if a feature is disabled GustoFFE SDK
 * @param featureId - The id of the feature
 */
export const isFXFeatureEnabled = async (featureId: string): Promise<boolean> => {
  const response = await http(`/gusto_ffe/fx_feature_enabled/${featureId}`);
  const data = await response.text();
  const result = data === 'true';
  notifyEvaluationListeners(featureId, result);
  return result;
};

/**
 * Enrolls a user into an experiment using GustoFFE SDK
 * Returns an object that contains the enrollment state, variation name, and variable if passed in.
 * @param experimentId - The id of the experiment
 * @param opts.variable - The name of the variable
 */
export interface enrollInExperimentDetailedInterface {
  enrolled: boolean;
  [key: string]: boolean | string;
}

export const enrollInExperimentDetailed = async (
  experimentId: string,
  variable?: string,
  skipExistingUsers = true
): Promise<enrollInExperimentDetailedInterface> => {
  const skipForSyntheticTest = getCookieValue('synthetic_test_skip_experiments');
  const skipForExistingUsers = skipExistingUsers && getCookieValue('has_signed_in');
  if (skipForSyntheticTest) return { enrolled: false };
  if (skipForExistingUsers) return { enrolled: false };
  const searchParams = getSearchParams();

  const response = await http(
    `/gusto_ffe/enroll_experiment_detailed/${experimentId}/${variable || ''}${searchParams}`
  );
  const data = await response.json();
  notifyEvaluationListeners(experimentId, !!data?.enrolled);
  return data;
};

/**
 * Check if the user is enrolled in the experiment in using GustoImpression
 * @param experimentId - The id of the experiment
 */
export const getReadOnlyEnrollment = async (experimentId: string): Promise<boolean> => {
  const response = await http(`/gusto_ffe/get_read_only_variation/${experimentId}`);
  const data = await response.text();
  return data?.startsWith('variation');
};

/**
 * Returns the variation name of the experiment the user is enrolled in using GustoImpression
 * @param experimentId - The id of the experiment
 */
export const getReadOnlyVariations = async (experimentId: string): Promise<string> => {
  const response = await http(`/gusto_ffe/get_read_only_variation/${experimentId}`);
  const data = await response.text();
  return data;
};
