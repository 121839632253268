import { datadogRum } from '@datadog/browser-rum';
import { addEvaluationListener } from 'helpers/gusto_ffe';
import getApplicationEnvironmentFromHost from '../environment';

const GIT_REVISION = 'none';

addEvaluationListener((experimentId, enabled) => {
  datadogRum.addFeatureFlagEvaluation(experimentId, enabled.toString());
});

datadogRum.init({
  applicationId: '1893c00c-20f4-4cae-88a6-17b41e96db8b',
  clientToken: 'pub54d46a178656397a77956339b9528678',
  service: 'gcorp',
  site: 'datadoghq.com',
  env: getApplicationEnvironmentFromHost(),
  version: GIT_REVISION,
  defaultPrivacyLevel: 'mask-user-input',
  sessionSampleRate: 10,
  sessionReplaySampleRate: 1,
  traceSampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  enableExperimentalFeatures: ['feature_flags'],
  trackUserInteractions: true,
  allowedTracingOrigins: [
    'http://gusto.com',
    'https://gusto.com',
    'http://gusto-staging.com',
    'https://gusto-staging.com',
    'http://gusto-dev.com',
    'https://gusto-dev.com',
    'http://localhost',
    /http:\/\/localhost:\d{1,5}/,
    'http://gusto-dev.com:3001',
  ],
});

export default datadogRum;
