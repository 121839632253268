/* eslint-disable no-param-reassign, class-methods-use-this, no-undef */
import getServiceWorkerStatus from './service_worker_helper';

class PerformanceHelper {
  /**
   * Executes a function after the DOM load event. If the DOM load event has already taken place,
   * we execute the function immediately.
   *
   * @param func  Non-null function to call on DOMContentLoaded (or onload) event
   * @param arg   Nullable argument to pass to the function
   */
  executeFunctionOnLoad(func, arg) {
    if (!document.readyState || document.readyState === 'complete') {
      func(arg);
      return;
    }

    if (document.addEventListener) {
      document.addEventListener(
        'DOMContentLoaded',
        () => {
          func(arg);
        },
        false
      );
    } else {
      window.attachEvent('onload', () => {
        func(arg);
      });
    }
  }

  getFirstPaintInformation() {
    const result = {};
    if (!window?.performance) {
      return result;
    }

    /*
     * Get paint timings ms since first fetch
     * Supported by Firefox and Chrome
     */
    const paintEntries = window.performance.getEntriesByType('paint');
    paintEntries.forEach(paintEntry => {
      result[paintEntry.name] = paintEntry.startTime;
    });
    return result;
  }

  /**
   * Report the time since load timing to Gusto Analytics
   * @returns {null|{href: string, loadInMS: number}|{href: string, loadInMS: number, downlink: number, rtt: number, effectiveType: string}}
   */
  getPageLoadInformation() {
    const pageLoadTimeInfo = {};
    let timeSinceLoadInMS = 0;

    // Detects Navigation Timing API support and tracks time since load in MS
    if (window.performance) {
      timeSinceLoadInMS = Math.round(performance.now());
    }
    const paintInfo = this.getFirstPaintInformation();
    if (timeSinceLoadInMS > 0) {
      pageLoadTimeInfo.pageLoad = {
        href: window.location.href,
        pathname: window.location.pathname,
        loadInMS: timeSinceLoadInMS,
        value: timeSinceLoadInMS,
        serviceWorkerStatus: getServiceWorkerStatus(),
        ...paintInfo,
      };
    }

    // Enhance page load information
    if (pageLoadTimeInfo.pageLoad == null) {
      return pageLoadTimeInfo;
    }

    // Add network information
    let connection = null;
    if (navigator) {
      connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    }

    if (connection) {
      pageLoadTimeInfo.downlink = connection.downlink;
      pageLoadTimeInfo.rtt = connection.rtt;
      pageLoadTimeInfo.effectiveType = connection.effectiveType;
    }

    return pageLoadTimeInfo;
  }
}

export default PerformanceHelper;
